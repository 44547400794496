<template>
	<div id="stateData">
		<Query></Query>
		<div class="c-query">
			<div class="item">
				设备状态：<span :class="isOnline?'online':'offline'">
					{{isOnline?'● 在线':'● 离线'}}
				</span>
			</div>
			<div class="item">在线时间：2021-03-10 17:23:19</div>
			<div class="item">在线时长：18小时52分钟</div>
		</div>
		<div class="c-container-box">
			<el-table :data="dataArr">
				<el-table-column type="index" label="序号" width="100" show-overflow-tooltip></el-table-column>
				<el-table-column prop="onlineTime" label="上线时间"></el-table-column>
				<el-table-column prop="offlineTime" label="脱机时间"></el-table-column>
				<el-table-column prop="duration" label="在线时长"></el-table-column>
			</el-table>
			<el-pagination background :page-sizes="[15, 20, 25, 30]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="30" v-show="dataArr.length>0">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import Query from '../../components/Query.vue';

	export default {
		data() {
			return {
				isOnline: true,
				dataArr: []
			}
		},
		components: {
			Query
		},
		mounted() {
			let dataArr = new Array();
			for (let i = 0; i < 15; i++) {
				dataArr.push({
					onlineTime: '2021-03-01 16:34:28',
					offlineTime: '2021-03-06 09:45:49',
					duration: '4天17小时11分钟'
				})
			}
			this.dataArr = dataArr;
		}
	}
</script>

<style lang="scss">

</style>
